import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { SocialLinks } from './SocialLinks';

type FooterLink = {
    linkText: string;
    url: string;
};

type SocialLink = {
    icon: {
        file: {
            url: string;
        };
    };
    url: string;
    name: string;
};

export type FooterData = {
    footerLink: FooterLink[];
    socialLinks: SocialLink[];
};

const Footer = () => {
    const data = useStaticQuery(graphql`
        {
            allContentfulFooter {
                nodes {
                    footerLink {
                        linkText
                        url
                    }
                    socialLinks {
                        icon {
                            file {
                                url
                            }
                        }
                        url
                        name
                    }
                }
            }
        }
    `);

    const footerData: FooterData = data.allContentfulFooter.nodes[0];

    return (
        <footer className="px-16 py-8 bg-darkGray text-offWhite footer">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 place-content-center">
                <SocialLinks iconClassnames='w-8' />
                <div className="flex flex-col md:flex-row md:space-x-8 w-full justify-center">
                
                            <Link className="footer-link justify-center self-center" to='/privacy' key='/privacy'>
                                <p>Privacy</p>
                            </Link>
                            <Link className="footer-link justify-center self-center" to='/terms' key='/terms'>
                                <p>Terms of Use</p>
                            </Link>
                </div>
                <div className="flex flex-row justify-center md:justify-end">
                    <img
                    id="footer"
                        src={require("assets/images/logo.svg")}
                        alt="site logo"
                        width={256}
                    />
                </div>
            </div>
        </footer>
    );
};

Footer.defaultProps = {
    menus: null,
};

Footer.propTypes = {
    menus: PropTypes.any,
};

export default Footer;
