import { graphql, Link, useStaticQuery } from "gatsby";
import { Button } from "luxd";
import React, { ReactElement, useEffect } from "react";
import { discordLink, live } from "../constants";
import useAccount from "../hooks/useAccount";
import { FaDownload } from "react-icons/fa";

type MenuItem = {
    button?: boolean;
    primaryButtom?: boolean; // yes ik this is a typo just leave it we cant change it
    title: string;
    url: string;
    id: string;
};

type Props = {
    menus: {
        name: string;
        type: string;
        menuItems: MenuItem[];
    };
};

const Header = (): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulMenu {
                nodes {
                    menuItems {
                        title
                        url
                    }
                    name
                    type
                }
            }
        }
    `).allContentfulMenu.nodes;

    const menus = data;
    // end of modified code
    let navMenuButton, navMenu;

    const mainMenu =
        menus !== null && menus !== undefined
            ? menus.find(menu => menu.type === "primary")
            : null;

    const toggleNav = () => {
        navMenu.classList.toggle("nav__menu--active");

        navMenuButton.classList.toggle("nav__menu-button--active");

        document.body.classList.toggle("disable-scroll");
    };

    const closeMenu = () => {
        if (navMenuButton.classList.contains("nav__menu-button--active")) {
            navMenuButton.classList.remove("nav__menu-button--active");

            navMenu.classList.remove("nav__menu--active");

            document.body.classList.remove("disable-scroll");
        }
    };

    useEffect(() => {
        navMenuButton = document.getElementById("nav_menu_button");

        navMenu = document.getElementById("nav_menu");
    });

    const [user, loading, error] = useAccount();

    const isAuthenticated = user !== undefined;

    return (
        <header className="header mx-0">
            <Link to="/">
                <img
                    src={require("assets/images/logo.svg")}
                    alt="Streamlux logo"
                    className="w-7/12 h-12"
                />
            </Link>
            {mainMenu !== null && mainMenu !== undefined && (
                <nav className="nav">
                    <h2 className="hidden">Streamlux Top navigation</h2>
                    <ul
                        id="nav_menu"
                        className="nav__menu space-y-4 lg:space-y-0"
                    >
                        <li key="home" className="link">
                            <Link to={"/"} onClick={closeMenu}>
                                Home
                            </Link>
                        </li>
                        <li key="faq" className="link">
                            <Link to={"/faq"} onClick={closeMenu}>
                                FAQ
                            </Link>
                        </li>
                        <li key="discord" className="link">
                            <a
                                href={discordLink}
                                target="_blank"
                                rel="noopener"
                                onClick={closeMenu}
                            >
                                Discord
                            </a>
                        </li>
                        <li key="help" className="link">
                            <a
                                href="https://help.streamlux.com/"
                                target="_blank"
                                rel="noopener"
                                onClick={closeMenu}
                            >
                                Help
                            </a>
                        </li>
                        {live && (
                            <>
                                {isAuthenticated ? (
                                    <li key="accountButton">
                                        <Link to="/account">
                                            <Button color="primary">
                                                My Account
                                            </Button>
                                        </Link>
                                    </li>
                                ) : (
                                    <li key="signupButton">
                                        <Link to="/signup">
                                            <Button color="primary">
                                                Sign up
                                            </Button>
                                        </Link>
                                    </li>
                                )}
                            </>
                        )}
                        <li key="downloadButton">
                            <Link to="/download">
                                <Button color="secondary" icon={<FaDownload />}>
                                    Download
                                </Button>
                            </Link>
                        </li>
                        <li key="startNow">
                            <Button
                                color="primary"
                                onClick={() =>
                                    window.open("https://streamlux.com/app")
                                }
                            >
                                Start Now
                            </Button>
                        </li>
                    </ul>
                    <button
                        id="nav_menu_button"
                        className="nav__menu-button"
                        aria-label="mobile menu"
                        onClick={toggleNav}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </nav>
            )}
        </header>
    );
};

export default Header;
