import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement } from "react";
import parse from "html-react-parser";

type BannerContent = {
    id: string;
    active: boolean;
    html: string;
};

export const Banner = (): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulBanner {
                edges {
                    node {
                        id
                        active
                        content {
                            nodeType
                            childContentfulRichText {
                                html
                            }
                        }
                    }
                }
            }
        }
    `);

    const bannerHtml =
        data.allContentfulBanner.edges[0].node.content.childContentfulRichText
            .html;

    const banner: BannerContent = {
        id: data.allContentfulBanner.edges[0].node.id,
        active: data.allContentfulBanner.edges[0].node.active,
        html: bannerHtml,
    };

    return (
        <div className="flex flex-col text-base px-16 text-center md:flex-row justify-center banner__container">
            <a href="https://streamlux.com/app">Try out Streamlux in your browser!</a>
        </div>
    );
};
