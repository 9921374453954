import { useContext, useEffect, useState } from "react";
import { AppwriteContext } from "../appwriteContext";
import { Error, User } from "../types";

const useAccount = (): [User | undefined, boolean, Error | undefined] => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | undefined>();
    const [res, setRes] = useState<User | undefined>();
    const appwrite = useContext(AppwriteContext);

    useEffect(() => {
        setLoading(true);
        const getAccount = appwrite.account.get();
        getAccount.then(user => {
            setRes(user as User);
            setLoading(false);
        });
        getAccount.catch((err: Error) => {
            setLoading(false);
            setError(err);
        });
    }, []);

    return [res, loading, error];
};

export default useAccount;
