import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import sal from "sal.js";

import Header from "./header";
import Footer from "./footer";
import { Banner } from "./banner"

const Layout = ({ children }: any) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    // Initialize scroll animations
    useEffect(() => {
        sal();
    }, []);

    return (
        <div
            className={"flex flex-col"}
            style={{ minHeight: "100vh", maxWidth: "100vw" }}
        >
            <div>
                <h1 className="hidden">{data.site.siteMetadata.title}</h1>
                <Header />
                <Banner />
            </div>
            <div className="flex-grow flex flex-col">{children}</div>
            <div className="static bottom-0 w-full">
                <Footer />
            </div>
        </div>
    );
};

Layout.defaultProps = {
    menus: null,
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    menus: PropTypes.any,
};

export default Layout;
